<template>
  <div>
    <CRow>
      <CCol lg="6">
        <CCardBody>
          <CDataTable
                  :items="apiDatas"
                  :fields="fields"
                  column-filter
                  table-filter
                  items-per-page-select
                  :items-per-page="50"
                  sorter
                  :sorterValue="{
                    column: 'line_order'
                  }"
                  pagination
                  clickable-rows
                  @row-clicked="rowClicked"
                  @page-change="pageChange"
                  hover
          >
            <template #actions="{ item }">
              <td>
                <CButton @click="removeLine(item)" color="primary">Удалить</CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCol>
      <CCol lg="6">
        <CCard>
          <CCardHeader>
            <strong>Добавить Нитку</strong>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="createLine()">
              <CInput
                      type="hidden"
                      name="action"
                      v-model="form.action"
                      required
              />
              <CInput
                      label="Введите название Нитки"
                      v-model="form.line_name"
                      required
              />
              <CInput
                      type="number"
                      label="Введите порядковый номер Нитки"
                      v-model="form.line_order"
                      required
              />
                <label>Выберите ГРС</label>
                <model-list-select :list="grsDatas"
                                   v-model="form.grs_id"
                                   option-value="value"
                                   option-text="label"
                                   :change="getCords()"
                                   placeholder="Выберите ГРС">
                </model-list-select>

                <br>
                <label>Выберите тип линии</label>
                <model-list-select :list="lineTypes"
                                   v-model="form.line_type"
                                   option-value="value"
                                   option-text="label"
                                   placeholder="Выберите тип линии">
                </model-list-select>
                <br>
                <br>

                <CInput
                        description="Введите координаты [Широта,Долгота]"
                        label="Координаты"
                        v-model="form.cord"
                />
                <div class="form-group">
                  <input type="checkbox" id="show_on_map" :value="0" v-model="form.show_on_map" class="mr-2">
                  <label for="show_on_map" class="form-check-label">Выводить на карте</label>
                </div>
                <br>

              <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Добавить</CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
          </CCardFooter>
        </CCard>
      </CCol>

    </CRow>
  </div>
</template>

<script>
  import axios from 'axios'
  import {ModelListSelect} from "vue-search-select"
  import 'vue-search-select/dist/VueSearchSelect.css'


  const fields = [
    {
      key: "line_order",
      label: "№",
      _style: "width:10%;",
      filter: false
    },
    {
      key: "name",
      label: "Название Нитки",
      _style: "min-width:100%;",
      // sorter: false
    },
    {
      key: "grs_name",
      label: "Грс",
      _style: "min-width:100%;",
      // sorter: false
    },
    {
      key: "line_type",
      label: "Тип нитки",
      _style: "min-width:100%;",
      // sorter: false
    },
    {
      key: "on_map",
      label: "На карте",
      // sorter: false
    },
    // {
    //   key: "actions",
    //   label: "Действия",
    //   _style: "min-width:1%;",
    //   sorter: false,
    //   filter: false
    // },
  ];

  export default {
    name: 'Lines',
    data: function() {
      return {
        visibleLiveDemo: false,
        apiDatas: [],
        grsDatas: [],
        operatorDatas: [],
        lineTypes:[],
        itemActive: 0,
        tableFields: ['line_order', 'id', 'name', 'grs_name', "line_type", "on_map"],
        form: {
          action: 'add_line',
          line_name: "",
          grs_id: "",
          line_order: "",
          line_type: "",
          cord: "",
          show_on_map: 0,
        },
        fields,
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },

    beforeRouteLeave(to, from, next) {
      to.meta.lid = this.itemActive;
      next()
    },
    methods: {
      getDataFromApi () {
        axios
          .get(`${axios.defaults.baseURL}?action=get_all_lines`)
          .then(response => (this.apiDatas = response.data))
      },
      getCords () {
          axios
              .get(`${axios.defaults.baseURL}?action=get_cords&grs_id=${this.form.grs_id}`)
              .then((response) => {
                  console.log(response.data)
                  this.form.cord = response.data[0].cord
              })
              .catch((error) => {
                  console.log(error)
              })
      },
      createLine () {

        let bodyFormData = new FormData();

        bodyFormData.append('action', this.form.action);
        bodyFormData.append('line_name', this.form.line_name);
        bodyFormData.append('grs_id', this.form.grs_id);
        bodyFormData.append('line_order', this.form.line_order);
        bodyFormData.append('line_type', this.form.line_type);
        bodyFormData.append('show_on_map', this.form.show_on_map ? 1 : 0);
        bodyFormData.append('cord', this.form.cord);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            this.form.line_name = "";
            this.form.grs_id = "";
            this.form.line_order = "";
            this.form.line_type = "";
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      removeLine (item) {

        let bodyFormData = new FormData();

        bodyFormData.append('action', "remove_line");
        bodyFormData.append('line_id', item.id);

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then(() => {
            //Perform Success Action
            this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      rowClicked (item, index) {
        this.itemActive = item.id;
        this.$router.push({path: `lines/${index}`})
      },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      }
    },
    mounted() {
      axios
        .get(`${axios.defaults.baseURL}?action=get_all_lines`)
        .then((response) => {
          response.data.map((el) => {
            this.apiDatas.push({
              id: el.id,
              name: el.name,
              grs_name: el.grs_name,
              line_order: parseFloat(el.line_order),
              line_type: el.line_type,
              on_map: el.on_map
            })
          });
          // this.apiDatas = response.data;
          // console.log(responce.data)
        });
      axios
        .get(`${axios.defaults.baseURL}?action=get_allgrses&no_operators=1`)
        .then((response) => {
          response.data.map((el) => {
            this.grsDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_all_linetypes`)
        .then((response) => {
          response.data.map((el) => {
            this.lineTypes.push({
              value: el.id,
              label: el.name
            })
          });
        })
    },
    components: {
      ModelListSelect
    }
  }
</script>
